import {EventEmitter, Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  onChangeEvent = new EventEmitter();

  private messageSource = new BehaviorSubject<any>('');
  showSubmitBtn = true;
  toBeDeleteReferringRequiredFrom = [];

  // currentMessage = this.messageSource.asObservable();


  constructor(
    private restangular: Restangular,
    private httpClient: HttpClient,
  ) {
  }

  currentMessage(message: string) {
    this.messageSource.next(message)
  }

  getCurrentMessage(): BehaviorSubject<any> {
    return this.messageSource;
  }

  save(data) {
    return this.restangular.one('dynamic-form/save').customPOST(data);
  }

  getPaginatedFormList(data) {
    return this.restangular.one('dynamic-form/paginated-list').customPOST(data);
  }

  getFormList() {
    return this.restangular.one('dynamic-form/list').customGET();
  }

  getSectionData(code: any) {
    return this.restangular.one('section/list').customPOST(code);
  }

  getSectionList() {
    return this.restangular.one('form-section/list').customGET();
  }

  uploadFiles(formData, headers) {
    return this.httpClient.post('slsea-service/upload/uploadFile', formData, {headers: headers});
  }

  getDataFromApi(url: any) {
    return this.restangular.one(url).customGET();
  }

  getForm(id: any) {
    return this.restangular.one('dynamic-form').customGET('show', {id: id});
  }

  getTourData() {
    return this.restangular.one('dashboard/tips').customGET();
  }
}
